import { useLayoutEffect } from 'react';

const useLockBodyScroll = (open) => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;  
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (open) {
      if (isIOS) {
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`; // Capture the scroll position
        document.body.style.width = '100%';
        document.body.style.height = '100vh';
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'hidden';
      }
    }

    return () => {
      if (isIOS) {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        document.body.style.height = '';
        document.body.style.overflow = originalStyle;
        window.scrollTo(0, parseInt(scrollY || '0') * -1); // Reset scroll position
      } else {
        document.body.style.overflow = originalStyle;
      }
    };
  }, [open]);
};

export default useLockBodyScroll;