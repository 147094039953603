import React, { useState, useEffect } from 'react';
import { Button, IconButton,  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Autocomplete } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import useFetchTagsPrepMeal from '../API/useFetchTagsPrepmeal'; // Adjust the import path
import useFetchListIngredients from '../API/fetchedIngredients';
import { useAlert } from '../Context/alertContext'; // Adjust the import path

const PrepmealForm = (props) => {

  const { fetchedTags } = useFetchTagsPrepMeal();
  const {fetchedIngredients} = useFetchListIngredients()


  const [calories, setCalories] = useState(0);
  const [carb, setCarb] = useState(0);
  const [protein, setProtein] = useState(0);
  const [fat, setFat] = useState(0);


  const [showIngredientList, setShowIngredientList]  = useState([])                                          
  const [prepmeal, setPrepmeal] = useState('');
  const [totalPortion, setTotalPortion] = useState('');
  const [tags, setTags] = useState([]);
  const [portions, setPortions] = useState([{ ingredient_id: '', ingredient: '', quantity: '' }]);

  const { showAlert } = useAlert(); // Use the alert context

  const [fieldErrors, setFieldErrors] = useState({});

  //editRow
  useEffect(() => {
    if (props.editRow){
      console.log("EDIT THIS", props.editRow )
      setValues(props.editRow)
    }
    // eslint-disable-next-line
  }, [props.editRow]);

  const validateForm = () => {
    const errors = {};
    let formIsValid = true;

    // Validate prepmeal and totalPortion fields
    if (!prepmeal.trim()) {
      errors.prepmeal = true;
      formIsValid = false;
    }

    console.log(totalPortion)
    if (!totalPortion) {
      errors.totalPortion = true;
      formIsValid = false;
    }

    // Validate portions
    portions.forEach((portion, index) => {
      if (!portion.ingredient || !portion.quantity) {
        errors[`portion${index}`] = true;
        formIsValid = false;
      }
    });

    setFieldErrors(errors);
    return formIsValid;
  };

  const handleDeletePortion = (index) => {
    setPortions((prevPortions) => prevPortions.filter((_, i) => i !== index));
  };

  const handleClose = () => {
    resetValue()
    props.setOpen(false);
    props.handleUpdate(null)
  };

  const resetValue = () => {

    setCalories(0);
    setCarb(0);
    setProtein(0);
    setFat(0);

    setPrepmeal('');
    setTotalPortion('');
    setTags([]);
    setPortions([{ ingredient_id: '', ingredient: '', quantity: '' }]);
    setTags( [])
  }

  const setValues = (selectedRow) => {




    setPrepmeal(selectedRow.prepmeal);
    setTotalPortion(selectedRow.total_portion);
    setPortions(selectedRow.portions_definition);
    setTags(selectedRow["tags"])

    console.log(selectedRow.portions_definition)
    console.log(fetchedIngredients)

    props.setOpen(true);
  }

  useEffect(() => {
    console.log(portions)
    console.log(fetchedIngredients)
    calculateTotalNutrition()
  }, [portions]);


  useEffect(() => {
    setShowIngredientList(fetchedIngredients)
  }, [fetchedIngredients]);


  const handleAddPortion = () => {
    setPortions([...portions, { ingredient_id: '', ingredient: '', quantity: 0 }]);
  };

  const handleSubmit = async () => {

    if (validateForm()) {
      // Submit the form
      const PrepMealData = {
        prepmeal,
        total_portion: Number(totalPortion), // ensure numerical data is properly formatted
        portions_definition:portions,
        tags
      };

    let methodRequest = ""
    let urlRequest = ""


    if (props.editRow){
      methodRequest = "PUT"
      urlRequest = '/api/prepmeal/'+props.editRow["_id"]
      
    }else{
      methodRequest = "POST"
      urlRequest = '/api/prepmeal'
    }

    try {
      const response = await fetch(urlRequest, {
        method: methodRequest,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(PrepMealData)
      });

      const result = await response.json();
      if (!response.ok) {
          if (response.status === 400 || response.status === 409) {
              // Bad Request
              const errorMessage = result.error || 'There is an error with the input.';
              throw new Error(errorMessage);
          } else if (response.status === 500) {
              // Internal Server Error
              throw new Error('Server error occurred. Please try again later.');
          } else {
              // Other types of errors
              throw new Error('An unexpected error occurred. Please try again.');
          }
      }
  

      //setAlertInfo({ open: true, severity: 'success', message: 'Ingredient added successfully!' });
      if (!props.editRow) props.updateTable()
      showAlert('success', result.message);
      if (props.editRow){
        props.handleUpdate(props.editRow)
      }else{
        props.handleUpdate(PrepMealData)
      }
      props.setOpen(false); // Optionally close the dialog on success
      
    } catch (error) {
      console.error('Error submitting ingredient:', error);
      showAlert('error', error.message);
      
    }
    } else {
      // Form validation failed
      console.log('Form validation failed!');
    }
    
    
      
  };

  const handleQuantityChange = (index, value) => {
    // Convert value to an integer. Use parseInt and check if it's NaN
    const intValue = parseInt(value, 10);
    
    // If intValue is Not-a-Number (NaN), set it to 0, otherwise use intValue
    const validValue = isNaN(intValue) ? 0 : intValue;

    const newPortions = [...portions];
    newPortions[index].quantity = validValue;
    setPortions(newPortions);
  };

  const calculateTotalNutrition = () => {
      let totalCalories = 0;
      let totalCarb = 0;
      let totalProtein = 0;
      let totalFat = 0;
  
      portions.forEach(port => {
        const ingredientDetails = fetchedIngredients.find(ing => ing.ingredient_id === port.ingredient_id);
        if (ingredientDetails) {
          if (ingredientDetails.unit !== "piece"){
            totalCalories += (ingredientDetails.calories * port.quantity) / 100;
            totalCarb += (ingredientDetails.carb * port.quantity) / 100;
            totalProtein += (ingredientDetails.protein * port.quantity) / 100;
            totalFat += (ingredientDetails.fat * port.quantity) / 100;
          }
          else{
            totalCalories += (ingredientDetails.calories * port.quantity);
            totalCarb += (ingredientDetails.carb * port.quantity);
            totalProtein += (ingredientDetails.protein * port.quantity);
            totalFat += (ingredientDetails.fat * port.quantity);
          }

        }
      });
  
      setCalories(totalCalories);
      setCarb(totalCarb);
      setProtein(totalProtein);
      setFat(totalFat);
    };


  const handleIngredientChange = (index, value) => {
    const newPortions = [...portions];
    if (value){
        newPortions[index].ingredient = value.ingredient;
        newPortions[index].ingredient_id  = value.ingredient_id;
    }
    else{
        newPortions[index].ingredient = ""
        newPortions[index].ingredient_id = ""
    }
    const ingredientIdsList = newPortions.map(portion => portion.ingredient_id);
    setPortions(newPortions)
    setShowIngredientList(fetchedIngredients.filter(item => !ingredientIdsList.includes(item.ingredient_id)))
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle style={{paddingBottom:"0px"}}>Create Prepmeal </DialogTitle>
      <div style={{paddingLeft:"26px"}}>calories: {calories.toFixed(2)}kcal | carb: {carb.toFixed(2)}g | protein: {protein.toFixed(2)}g | fat: {fat.toFixed(2)}g</div> 
      <DialogContent>
        <TextField
          required
          autoFocus
          margin="dense"
          label="Prepmeal"
          fullWidth
          value={prepmeal}
          onChange={(e) => setPrepmeal(e.target.value)}
          style={{ borderColor: fieldErrors.prepmeal ? 'red' : '' }}
          />
          {fieldErrors.prepmeal && <span style={{ color: 'red' }}>Please enter prepmeal</span>}
        <TextField
          required
          margin="dense"
          label="Total Portion"
          fullWidth
          value={totalPortion}
          onChange={(e) => setTotalPortion(e.target.value)}
        />
         {fieldErrors.totalPortion && <span style={{ color: 'red' }}>Please enter total portion</span>}
        <Autocomplete
                multiple
                options={fetchedTags} // Use fetchedTags here
                freeSolo
                value={tags} // Use this state for handling selected tags
                onChange={(event, newValue) => {
                setTags(newValue);
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    margin="dense"
                    label="Tags"
                    placeholder="Add Tags"
                />
                )}
        />
        {portions.map((portion, index) => (
          <>
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '8px', marginBottom: '8px', width: "100%" }}>
              <Autocomplete
                options={showIngredientList}
                getOptionLabel={(option) => option.ingredient}
                value={portion}
                renderInput={(params) => <TextField {...params} required label="Ingredient" style={{ width: "40vh" }} />}
                onChange={(event, value) => handleIngredientChange(index, value)}
              />
              <TextField
                required
                margin="dense"
                label="Quantity"
                fullWidth
                style={{ marginBottom: '0px', marginLeft: '8px', marginTop: '0px', width:"15vh"}}
                value={portion.quantity}
                onChange={(e) => handleQuantityChange(index, e.target.value)}
              />
              <IconButton 
                onClick={() => handleDeletePortion(index)} 
                style={{ marginLeft: '8px' }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
            {fieldErrors[`portion${index}`] && <span style={{ color: 'red' }}>Please enter ingredient and quantity</span>}
          </>
        ))}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleAddPortion} variant="outlined" color="primary" disabled={portions.length === fetchedIngredients.length}>
            Add Portion
          </Button>
        </div>
       
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {props.editRow ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrepmealForm;