import React, { useState } from "react";
import NutrientChart from "../Components/NutrientChart";
import PrepmealTable from '../Components/PrepmealTable'
import PicturePrepmeal from "../Components/PicturePrepmeal";
import PrepmealDetails from "../Components/PrepmealDetails";
import PrepmealForm from "../Components/PrepmealForm";
import './PrepMeal.css';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useAuth } from '../Context/AuthContext';
import { Navigate } from 'react-router-dom';

export default function PrepMeal(props) {

  const { user, isLoading } = useAuth();

  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editRow, setEditRow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [isTableRefresh, setIsTableRefresh] = useState(false);

  const handleUpdate = (newRow) => {

    console.log("update newRow", newRow)
  
    if (newRow){
      let row = SearchRow(newRow["prepmeal"])
      if (row){
        updateTable(row["_id"])
        console.log("update",row["_id"] )
      }
    }else{
      setEditRow(false)
    }
  };

  const updateTable = async (id) => {

    // Initialize the base URL
    let url = "/api/prepmeal/"+id;
    // Append the search query if it's not empty
    try {
    const response = await fetch(url);
    const data = await response.json();
    console.log("new data",data)
    replaceObject(id,data)
    setSelectedRow(data)
    } catch (error) {
    console.error('Failed to fetch data:', error);
    } 
  };
  
  // Function to replace an object in the array
  const replaceObject = (_id, newObject) => {
    setRows(currentRows => {
        // Map through the current rows to find the object to replace
        return currentRows.map(row => {
            if (row._id === _id) {
                // Replace the object
                return newObject;
            }
            // Return the object unchanged if not the target
            return row;
        });
    });
  };

  const SearchRow = (searchTerm) => {
  return rows.find(row => row._id === searchTerm || row.prepmeal === searchTerm);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickOpen = () => {
    setOpen(true);
    handleClose();
  };

  if (isLoading) {
    return <h1>Loading...</h1>; // Or any loading indicator
  }

  if (!user) {
    return <Navigate to="/login" replace />;
} 

return (
  <div class="grid-prepmeal">
  <div class="grid-item-prepmeal grid-item-title">
    <PrepmealForm open={open} setOpen={setOpen} selectedRow={selectedRow} editRow={editRow} updateTable={() => setIsTableRefresh(true)} handleUpdate={handleUpdate}/>
    PrepMeal
    <IconButton onClick={handleClick}>
        <MoreVertIcon />
    </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClickOpen}>Add New PrepMeal</MenuItem>
      </Menu>
    </div>
    <div className={`grid-item-prepmeal grid-prepmeal-info-effect ${selectedRow ? '' : 'nonselected'}`}>
        <div class="grid-info-prepmeal">
          <div class="grid-info-item-prepmeal grid-info-item-prepmeal-info">
            <PrepmealDetails selectedRow={selectedRow} handleUpdate={handleUpdate}/>
          </div>
          <div class="grid-info-item-prepmeal grid-info-item-prepmeal-diagram">
            <NutrientChart selectedRow={selectedRow}/>
          </div>
          <div class="grid-info-item-prepmeal">
          <PicturePrepmeal selectedRow={selectedRow} handleUpdate={handleUpdate} />
          </div>
      </div>
    </div>
    <div className={`grid-item-prepmeal ${selectedRow ? '' : 'grid-item-prepmeal-selected'}`}>
        <div class="prepmeal-card-scroll">
          <PrepmealTable selectedRow={selectedRow} setIsTableRefresh={setIsTableRefresh} isTableRefresh={isTableRefresh} setSelectedRow={setSelectedRow} setEditRow={setEditRow} rows={rows} setRows={setRows}/>
        </div>
      </div>
</div>
)
}