// src/App.js
import React, { useState} from "react";
import Header from './Components/Header';
import './App.css';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';


import HomePage from './pages/HomePage' 
import Zutaten from './pages/Zutaten' 
import PrepMeal from './pages/PrepMeal' 
import CalendarPlan from "./pages/CalendarPlan";


function App() { 
  const [viewType, setViewType] = useState("home");

  return(
  <Router>
    <div className="App"> 
      <div className="Header">
        <Header viewType={viewType}></Header>
      </div>
      <div className="Main">
                    <Routes>
                        <Route path="/" element={<HomePage/>} />
                        <Route path="/login" element={<HomePage/>} />
                        <Route path="/home" element={<HomePage/>} />
                        <Route path="/zutaten" element={<Zutaten/>} />
                        <Route path="/prepmeal" element={<PrepMeal/> } />
                        <Route path="/calendar" element={<CalendarPlan/>} />
                    </Routes>
                </div>
    </div>
  </Router>)
} 
export default App;