import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Box, Button } from '@mui/material';

const MenuConfigurationStep1 = (props) => {
  const [days, setDays] = useState(props.initialData.days);
  const [startDate, setStartDate] = useState(props.initialData.startDate);
  const [breakfast, setBreakfast] = useState(props.initialData.breakfast);
  const [meals, setMeals] = useState(props.initialData.meals);
  const [snacks, setSnacks] = useState(props.initialData.snacks);

  // Update local state if props.initialData changes
  useEffect(() => {
    setDays(props.initialData.days);
    setStartDate(props.initialData.startDate);
    setBreakfast(props.initialData.breakfast);
    setMeals(props.initialData.meals);
    setSnacks(props.initialData.snacks);
  }, [props.initialData]);

  const [errors, setErrors] = useState({
    days: '',
    startDate: '',
    breakfast: '',
    meals: '',
    snacks: '',
  });

  useEffect(() => {
    if (props.isValid && props.activeStep == 0){
        if (isValid()){
            props.setIsValid(false)
            props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }else{
            props.setIsValid(false)
        }

    }
   
  }, [props.isValid]);


  const validateDays = (value) => {
    if (!value) return 'Number of days is required';
    if (isNaN(value) || parseInt(value, 10) <= 0) return 'Enter a valid number of days';
    return '';
  };

  const validateDate = (value) => {
    if (!value) return 'Start date is required';
    return '';
  };

  const validateSelection = (value, field) => {
    if (value === '' || value === undefined) return `Please select a value for ${field}.`;
    return '';
  };

  const handleDaysChange = (event) => {
    const value = event.target.value;
    setDays(value);
    props.onChildDataChange({ days: value });
    setErrors({ ...errors, days: validateDays(value) });
  };

  const handleDateChange = (event) => {
    const value = event.target.value;
    setStartDate(value);
    console.log("data",value)
    props.onChildDataChange({ startDate: value });
    setErrors({ ...errors, startDate: validateDate(value) });
  };

  const handleBreakfastChange = (event) => {
    const value = event.target.value;
    setBreakfast(value);
    props.onChildDataChange({ breakfast: value });
    setErrors({ ...errors, breakfast: validateSelection(value, 'breakfast') });
  };

  const handleMealsChange = (event) => {
    const value = event.target.value;
    setMeals(value);
    props.onChildDataChange({ meals: value });
    setErrors({ ...errors, meals: validateSelection(value, 'meals') });
  };

  const handleSnacksChange = (event) => {
    const value = event.target.value;
    setSnacks(value);
    props.onChildDataChange({ snacks: value });
    setErrors({ ...errors, snacks: validateSelection(value, 'snacks') });
  };

  const isValid = () => {


    const daysError = validateDays(days);
    const dateError = validateDate(startDate);
    const breakfastError = validateSelection(breakfast, 'breakfast');
    const mealsError = validateSelection(meals, 'meals');
    const snacksError = validateSelection(snacks, 'snacks');

    if (daysError || dateError || breakfastError || mealsError || snacksError) {
      setErrors({
        ...errors,
        days: daysError,
        startDate: dateError,
        breakfast: breakfastError,
        meals: mealsError,
        snacks: snacksError,
      });
      return false; // Prevent the form from submitting
    }

    // Place where you would handle the form submission logic
    console.log('Form Submitted', { days, startDate, breakfast, meals, snacks });
    return true;
  };

  return (
    <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}>
      <TextField
        label="Number of Days"
        type="number"
        value={days}
        onChange={handleDaysChange}
        error={!!errors.days}
        helperText={errors.days}
        variant="outlined"
      />
      <TextField
        label="Start Date"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={startDate}
        onChange={handleDateChange}
        error={!!errors.startDate}
        helperText={errors.startDate}
        variant="outlined"
      />
      <TextField
        select
        label="Breakfasts per day"
        value={breakfast}
        onChange={handleBreakfastChange}
        error={!!errors.breakfast}
        helperText={errors.breakfast || "Select number of breakfasts"}
      >
        {[0, 1].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Meals per day"
        value={meals}
        onChange={handleMealsChange}
        error={!!errors.meals}
        helperText={errors.meals || "Select number of main meals"}
      >
        {[2, 3, 4, 5].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Snacks per day"
        value={snacks}
        onChange={handleSnacksChange}
        error={!!errors.snacks}
        helperText={errors.snacks || "Select number of snacks"}
      >
        {[0, 1, 2, 3].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default MenuConfigurationStep1;
