
import React, { useState, useEffect, useRef } from 'react';
import { Table, Grid,  Radio, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Autocomplete, CircularProgress, Menu, MenuItem } from '@mui/material';
import useFetchTags from '../API/useFetchTags'; // Adjust the import path
import { useAlert } from '../Context/alertContext'; // Adjust the import path
import useLockBodyScroll from '../Context/useLockBodyScroll'; // Import the custom hook
import { useAuth } from '../Context/AuthContext';

const EnhancedTable = (props) => {
  
  const { user, makeAuthenticatedRequest } = useAuth();

  const [open, setOpen] = useState(false);
  useLockBodyScroll(open); // Use the hook with the current open state

  const { fetchedTags } = useFetchTags();
  const [search, setSearch] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const loader = useRef(null);

  const [selected, setSelected] = useState(0);

  const { showAlert } = useAlert(); // Use the alert context

    const fetchData = async () => {
        if (!hasMore) return;
        setLoading(true);
    
        // Initialize the base URL
        let url = `/api/ingredients?page=${page}`;
    
        // Append the search query if it's not empty
        if (search.trim() !== '') {
        url += `&search=${encodeURIComponent(search.trim())}`;
        }
    
        // Append the tags query if any tags are selected
        if (selectedTags.length > 0) {
        const tagsQuery = selectedTags.join(',');
        url += `&tags=${encodeURIComponent(tagsQuery)}`;
        }
        
        try {
        const response = await fetch(url);
        const data = await response.json();
        props.setRows(prev => [...prev, ...data.ingredients]); // Append new data
        setHasMore(data.hasMore); // Check if more data is available
        setPage(prev => prev + 1); // Increment the page number
        } catch (error) {
        console.error('Failed to fetch data:', error);
        } finally {
        setLoading(false);
        }
    };


    // Fetch data on search or tags change
    useEffect(() => {
      console.log("changedrows", props.selectedRow)
      if (!props.selectedRow){
          console.log("rows",props.rows)
          if (props.rows.length === 0 && (search !== "" || selectedTags.length > 0 )){
            console.log("reset")
            setSearch("")
            setSelectedTags([])
          }
          else{
            if (selected > 0){
              handleRowClick(selected-1)
            }
            else{
              handleRowClick(0)
            }
            
          }
        }
      // eslint-disable-next-line
    }, [props.rows]);


    useEffect(() => {
      if (props.isTableRefresh){
        console.log("Update Table")
        refreshData()
        props.setIsTableRefresh(false)
      }

   
      // eslint-disable-next-line
    }, [props.isTableRefresh]);
    

    // Fetch data on search or tags change
    useEffect(() => {
        props.setRows([]); // Clear current data
        setPage(1); // Reset pagination
        setHasMore(true); // Assume more data is available
        fetchData();


        // eslint-disable-next-line
      }, [search, selectedTags]);


    useEffect(() => {
    const observer = new IntersectionObserver(entries => {
        const firstEntry = entries[0];
        console.log('Observer triggered:', firstEntry.isIntersecting);
        if (firstEntry.isIntersecting && hasMore && !loading) {
        fetchData();

        }
    }, { threshold: 0.1 }); // Lower threshold to make it more sensitive




    const currentElement = loader.current;
    if (currentElement) {
        observer.observe(currentElement);
    }

    return () => {
        if (currentElement) {
        observer.unobserve(currentElement);
        }
    };
    }, [loader, hasMore, loading]);


    const refreshData = async () => {
        setLoading(true);
        let allData = []; // Array to store data from all pages
    
        // Loop through all pages up to the current page
        for (let currentPage = 1; currentPage <= page; currentPage++) {
            let url = `/api/ingredients?page=${currentPage}`;
        
            if (search.trim() !== '') {
                url += `&search=${encodeURIComponent(search.trim())}`;
            }
        
            if (selectedTags.length > 0) {
                const tagsQuery = selectedTags.join(',');
                url += `&tags=${encodeURIComponent(tagsQuery)}`;
            }
            
            try {
                const response = await fetch(url);
                const data = await response.json();
                allData = [...allData, ...data.ingredients];
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        }
    
        // Update props.rows only once with all the fetched data
        props.setRows(allData);
        setLoading(false);
    };


    const handleRightClick = (event, row, index) => {
        handleRowClick(index)
        event.preventDefault(); // Prevent the bprops.rowser's context menu from opening
        props.setSelectedRow(row); // Store the clicked row's data
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
              }
            : // Reopen the menu at the same position
              null,
        );
    };


    const handleRowClick = (index) => {
        props.setSelectedRow(props.rows[index])
        setSelected(index);
    };


    const handleClose = () => {
        setContextMenu(null);
    };
    

    const handleDelete = async () => {
      if (!props.selectedRow) return;
      
      const url = `/api/ingredient/${props.selectedRow._id}`;

      try {
          const response = await makeAuthenticatedRequest('DELETE', url, null);
          if (response.status === 200) {
          // Successfully deleted the ingredient, now remove it from the table
          props.setSelectedRow(null)
          props.setRows(prevRows => prevRows.filter(row => row._id !== props.selectedRow._id));
          console.log(`Deleted ingredient with ID: ${props.selectedRow._id}`);
          showAlert('success', response.data.message);
          }  
          else {
            throw new Error(`Failed with status ${response.status}`);
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Error data:', error.response.data);
            console.error('Error status:', error.response.status);
            showAlert('error', error.response.data.error || 'An error occurred');
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Error request:', error.request);
            showAlert('error', 'No response received');
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error message:', error.message);
            showAlert('error', error.message);
        }
    }
      
      
      // Close the context menu
      handleClose();
    };
    
    const handleEdit = () => {
    console.log('Edit action on row:', props.selectedRow);
    props.setEditRow(props.selectedRow)
    // Implement edit logic here
    handleClose();
    };



  return (
    <Paper sx={{ overflow: 'hidden', maxHeight: "55vh" }}>
      <Grid container spacing={2}>  {/* Adjust 'spacing' to control the space between fields */}
        <Grid item xs={6}> {/* 'xs' controls the width on extra-small to small screens */}
          <TextField
            label="Search Ingredients"
            variant="outlined"
            value={search}
            fullWidth
            onChange={(e) => {
              setSearch(e.target.value);
              props.setRows([]); // Reset data
              setPage(1); // Reset page
              setHasMore(true); // Reset pagination
            }}
            sx={{ m: 2 }}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            options={fetchedTags}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField {...params} label="Filter by Tags" />}
            value={selectedTags}
            onChange={(event, newValue) => {
              setSelectedTags(newValue);
              props.setRows([]); // Reset data
              setPage(1); // Reset page
              setHasMore(true); // Reset pagination
            }}
            sx={{ m: 2 }}
        />
        </Grid>
      </Grid>
      <TableContainer className="scrollableDiv">
      
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 600 }}>Ingredient</TableCell>
              <TableCell style={{ fontWeight: 600 }} align="right">Calories</TableCell>
              <TableCell style={{ fontWeight: 600 }} align="right">Carbs (g)</TableCell>
              <TableCell style={{ fontWeight: 600 }} align="right">Protein (g)</TableCell>
              <TableCell style={{ fontWeight: 600 }} align="right">Fat (g)</TableCell>
              <TableCell style={{ fontWeight: 600 }} align="right">Unit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row, index) => (
              <TableRow 
              key={row._id}  
              selected={selected === index} 
              onClick={() => handleRowClick(index)}
              onContextMenu={(e) => handleRightClick(e, row, index)}
              sx={{ cursor: 'pointer', backgroundColor: selected === index ? '#f4f4f4' : 'inherit' }}
              >
                    <TableCell component="th" scope="row">{row.ingredient}</TableCell>
                    <TableCell align="right">{row.calories}</TableCell>
                    <TableCell align="right">{row.carb}</TableCell>
                    <TableCell align="right">{row.protein}</TableCell>
                    <TableCell align="right">{row.fat}</TableCell>
                    <TableCell align="right">{row.unit}</TableCell>
                            
              </TableRow>
            ))}
            {loading && (
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div ref={loader} style={{ height: '50px', margin: '10px 0' }}>
        
        </div>

      </TableContainer>
        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
            contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
        >
            <MenuItem onClick={handleEdit} disabled={user.role !== 'admin'}>Edit</MenuItem>
            <MenuItem onClick={handleDelete} disabled={user.role !== 'admin'}>Delete</MenuItem>
        </Menu>
    </Paper>
  );
};

export default EnhancedTable;
