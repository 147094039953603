import React from "react";
import { useAuth } from '../Context/AuthContext';
import { Navigate } from 'react-router-dom';

import CreateNewGoalDialog from "../Components/CalendarPlan/CreateNewGoalDialog";

export default function CalendarPlan(props) {
    const { user, isLoading } = useAuth();

    if (isLoading) {
        return <h1>Loading...</h1>; // Or any loading indicator
      }
    
      if (!user) {
        return <Navigate to="/login" replace />;
    } 

return <>
        <div>Coming soon, CalendarPlan!</div>
        <CreateNewGoalDialog/>
      </>
}