import React, { useState } from "react";

import IngredientTable from '../Components/IngredientTable'
import IngredientForm from '../Components/IngredientForm'
import NutrientChart from "../Components/NutrientChart";
import PictureIngredient from "../Components/PictureIngredient";
import IngredientDetails from "../Components/IngredientDetails";

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useAuth } from '../Context/AuthContext';
import { Navigate } from 'react-router-dom';

import './Zutaten.css';


export default function Zutaten(props) {

  const { user, isLoading } = useAuth();


  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editRow, setEditRow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [isTableRefresh, setIsTableRefresh] = useState(false);



  const handleUpdate = (newRow) => {
  
    if (newRow){
      let row = SearchRow(newRow["ingredient"])
      if (row){
        updateTable(row["_id"])
        console.log("update",row["_id"] )
      }
    }else{
      setEditRow(false)
    }
  };

  const updateTable = async (id) => {


    // Initialize the base URL
    let url = "/api/ingredient/"+id;

    // Append the search query if it's not empty
    try {
    const response = await fetch(url);
    const data = await response.json();
    console.log("new data",data)
    replaceObject(id,data)
    setSelectedRow(data)
    } catch (error) {
    console.error('Failed to fetch data:', error);
    } 
};

  // Function to replace an object in the array
  const replaceObject = (_id, newObject) => {
    setRows(currentRows => {
        // Map through the current rows to find the object to replace
        return currentRows.map(row => {
            if (row._id === _id) {
                // Replace the object
                return newObject;
            }
            // Return the object unchanged if not the target
            return row;
        });
    });
  };

  const SearchRow = (searchTerm) => {
    return rows.find(row => row._id === searchTerm || row.ingredient === searchTerm);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setOpen(true)
    handleClose();
  };

  console.log(user)


  if (isLoading) {
    return <h1>Loading...</h1>; // Or any loading indicator
  }

  if (!user) {
    return <Navigate to="/login" replace />;
} 

return (
        <div class="grid-ingredients">
          <div class="grid-item-ingredients grid-item-title">
          <IngredientForm open={open} setOpen={setOpen} selectedRow={selectedRow} editRow={editRow} updateTable={() => setIsTableRefresh(true)} handleUpdate={handleUpdate} disabled/>
          Zutaten
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleEdit}>Add New Ingredient</MenuItem>
            </Menu>
          </div>
          <div class="grid-item-ingredients">
            <div class="grid-info-ingredients">
              <div class="grid-info-item-ingredients grid-info-item-ingredients-info">
                <IngredientDetails selectedRow={selectedRow}/>
                
                </div>
              <div class="grid-info-item-ingredients">
                <PictureIngredient selectedRow={selectedRow} handleUpdate={handleUpdate}/>
              </div>
              <div class="grid-info-item-ingredients grid-info-item-ingredients-diagram">
                <NutrientChart selectedRow={selectedRow}/>
              </div>
            </div>
          </div>
            <div class="grid-item-ingredients">
                <IngredientTable selectedRow={selectedRow} setIsTableRefresh={setIsTableRefresh} isTableRefresh={isTableRefresh} setSelectedRow={setSelectedRow} setEditRow={setEditRow} rows={rows} setRows={setRows}/>
            </div>
        </div>
)
}