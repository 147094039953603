import React, { useState, useEffect } from 'react';
import { Slider, TextField, Box, Typography, InputAdornment } from '@mui/material';

const limits = {
  carbohydrates: { min: 30, max: 60 },
  proteins: { min: 20, max: 45 },
  fats: { min: 5, max: 30 }
};

const caloriesPerGram = {
  carbohydrates: 4,
  proteins: 4,
  fats: 9
};




const NutritionalConfiguration = (props) => {



  const [values, setValues] = useState({
    carbohydrates: props.initialData.carb,
    proteins: props.initialData.protein,
    fats: props.initialData.fat
  });

  const [totalCalories, setTotalCalories] = useState(props.initialData.calories);

  useEffect(() => {


    if (props.isValid && props.activeStep == 1){
        if (isValid()){
            props.setIsValid(false)
            props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }else{
            props.setIsValid(false)
        }
  
    }
   
  }, [props.isValid]);
  
  const isValid = () => {
    const totalPercentage = values.carbohydrates + values.proteins + values.fats;
    if (totalCalories >= 1200 && totalCalories <= 6000 && totalPercentage === 100){
      props.onChildDataChange({
        calories:totalCalories,
        carb: values.carbohydrates,
        protein: values.proteins,
        fat: values.fats
      });
      return true
    }
    else{
      return false
    }
  };

  const adjustValuesProportionally = (slider, newValue) => {
    let otherSliders = Object.keys(values).filter(key => key !== slider);
    let remainingPercentage = 100 - newValue;
    let currentTotal = values[otherSliders[0]] + values[otherSliders[1]];
    let newValues = {
      ...values,
      [slider]: newValue
    };

    otherSliders.forEach((key) => {
      newValues[key] = Math.round((values[key] / currentTotal) * remainingPercentage);
      if (newValues[key] < limits[key].min) newValues[key] = limits[key].min;
      if (newValues[key] > limits[key].max) newValues[key] = limits[key].max;
    });

    const total = Object.values(newValues).reduce((acc, value) => acc + value, 0);
    if (total !== 100) {
      const diff = total - 100;
      newValues[otherSliders[0]] -= diff;
    }

    setValues(newValues);
    props.onChildDataChange({
      ...props.initialData,
      carb: newValues.carbohydrates,
      protein: newValues.proteins,
      fat: newValues.fats
    });
  };

  const handleSliderChange = (slider) => (event, newValue) => {
    if (newValue < limits[slider].min) newValue = limits[slider].min;
    if (newValue > limits[slider].max) newValue = limits[slider].max;

    adjustValuesProportionally(slider, newValue);
  };

  const handleInputChange = (slider) => (event) => {
    let newValue = Number(event.target.value);
    if (isNaN(newValue)) return;

    let newPercentage = Math.round((newValue / (totalCalories / caloriesPerGram[slider])) * 100);
    if (newPercentage < limits[slider].min) newPercentage = limits[slider].min;
    if (newPercentage > limits[slider].max) newPercentage = limits[slider].max;

    adjustValuesProportionally(slider, newPercentage);
  };

  const handleKeyPress = (slider) => (event) => {
    if (event.key === 'Enter') {
      handleInputChange(slider)(event);
    }
  };

  const calculateGrams = (percentage, nutrient) => {
    return Math.round((percentage / 100) * (totalCalories / caloriesPerGram[nutrient]));
  };

  useEffect(() => {
    Object.keys(values).forEach(slider => {
      let grams = calculateGrams(values[slider], slider);
      let inputField = document.getElementById(`input-grams-${slider}`);
      if (inputField) {
        inputField.value = grams;
      }
    });
  }, [values, totalCalories]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }}>
      <Box sx={{ width: 300, margin: 2 }}>
        <Typography gutterBottom>Total Calories</Typography>
        <TextField
          value={totalCalories}
          onChange={(e) => {
            setTotalCalories(Number(e.target.value));
            props.onChildDataChange({ ...props.initialData, calories: Number(e.target.value) })
          }}
          inputProps={{
            step: 50,
            min: 1000,
            max: 6000,
            type: 'number',
          }}
          fullWidth
        />
      </Box>
      {Object.entries(values).map(([slider, value]) => (
        <Box key={slider} sx={{ width: 300, margin: 2 }}>
          <Typography gutterBottom>{slider.charAt(0).toUpperCase() + slider.slice(1)}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Slider
              value={value}
              onChange={handleSliderChange(slider)}
              aria-labelledby={`input-slider-${slider}`}
              min={0}
              max={70}
              valueLabelDisplay="auto"
              valueLabelFormat={(x) => `${x}%`}
              marks={[
                { value: limits[slider].min, label: `${limits[slider].min}%` },
                { value: limits[slider].max, label: `${limits[slider].max}%` }
              ]}
              sx={{ flexGrow: 1 }}
            />
            <TextField
              id={`input-grams-${slider}`}
              defaultValue={calculateGrams(value, slider)}
              onKeyDown={handleKeyPress(slider)}
              InputProps={{
                endAdornment: <InputAdornment position="end">g</InputAdornment>,
              }}
              inputProps={{
                step: 1,
                min: 0,
                max: totalCalories / caloriesPerGram[slider],
                type: 'number',
              }}
              sx={{ width: 140 }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default NutritionalConfiguration;
