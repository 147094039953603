import React, { useState, useContext } from "react";

import logo from "../mealmap.png";

import './Header.css';


import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';

export default function Header(props) {


  const [viewType, setViewType] = useState("home");
  const navigate = useNavigate();
  const handleClick = (location) => {
    navigate('/'+location);
    setViewType(location)

  }

  const activate_menu = (location) => {
    console.log(location, viewType)
    if (location === viewType){
      return "menu-item menu-active"
    }
    else{
      return "menu-item"
    }
    
  }


  return (
    <div className="HeaderInner">
      <div>
        <img className="PrepMeal" width="116.3" height="50" src={logo} alt="MealMap"></img>
      </div>
      <div className="menu-Top">
          <Tooltip
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14],
                      },
                    },
                  ],
                },
              }}
            >
              <Button className={`menu-item ${viewType == "home" ? 'menu-active' :''}`} onClick={() => handleClick('home')}>Home</Button>
              <Button className={`menu-item ${viewType == "zutaten" ? 'menu-active' :''}`} onClick={() => handleClick('zutaten')}>Zutaten</Button>
              <Button className={`menu-item ${viewType == "prepmeal" ? 'menu-active' :''}`} onClick={() => handleClick('prepmeal')}>PrepMeal</Button>
              <Button className={`menu-item ${viewType == "calendar" ? 'menu-active' :''}`} onClick={() => handleClick('calendar')}>Calendar</Button>
        </Tooltip>
      </div>
    </div>
  );
}
