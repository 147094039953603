// src/App.js
import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react"; 

function NutrientChart(props) { 
  
  const [option, setOption] = useState({})

  useEffect(() => {
    console.log(props.selectedRow)
    if (props.selectedRow){
      setOption({
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal', // Position of the legend, horizontal
          bottom: 0, // Distance from the bottom of the chart container,
          left: 'center',
          showEmpty: true, // Ensure all items are displayed in the legend
          data: ['Carb', 'Protein', 'Fat'] // Names of the data series, should match the 'name' property in your data
        },
        series: [
                  {
                        name: 'Nahrungsverteilung',
                        type: 'pie',
                        radius: '65%',
                        label: {
                          formatter: '{c}g',
                        },
                        data: [
                          { value: props.selectedRow.carb, name: 'Carb' },
                          { value: props.selectedRow.protein, name: 'Protein' },
                          { value: props.selectedRow.fat, name: 'Fat' },
                        ],
                        emphasis: {
                          itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                          }
                        },
                        // Define colors for the elements
                        color: ['#4682B4', '#32CD32', '#FF6347'] // Example colors (steelblue, limegreen, tomato)
                  }
                ]
      })

      }
    else{
      setOption({
          tooltip: {
            trigger: 'item'
          },
          series: [
                    {
                          name: 'Nahrungsverteilung',
                          type: 'pie',
                          radius: '50%',
                          label: {
                            formatter: '{c}',
                          },
                          data: [
                            { value: 0, name: 'Carb' },
                            { value: 0, name: 'Eiweiss' },
                            { value: 0, name: 'Fett' },
                          ],
                          emphasis: {
                            itemStyle: {
                              shadowBlur: 10,
                              shadowOffsetX: 0,
                              shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                          }
                    }
                  ]
        })
    }


    // eslint-disable-next-line
  }, [props.selectedRow]);







return <ReactEcharts  
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
        option={option} />;
} 
export default NutrientChart;