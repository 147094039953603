import React, { useState, useEffect, useRef } from 'react';
import { Card, Popover, CardMedia, CardContent, Typography, Chip } from '@mui/material';
import defaultImage from "../default_prepmeal.png";


export default function CardMeal(props) {


    const [picture, setPicture] = useState(defaultImage);
    const tagContainerRef = useRef(null);
    const [visibleTagsCount, setVisibleTagsCount] = useState(props.tags.length);
    const [anchorEl, setAnchorEl] = useState(false);


    const fetchImage = async (pictureId) => {
      try {
          const response = await fetch(`/api/prepmeal/${pictureId}/picture`);
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const imageBlob = await response.blob();
          const imageObjectURL = URL.createObjectURL(imageBlob);
          setPicture(imageObjectURL);
      } catch (error) {
          console.log('Failed to fetch image');
      }
    };

    useEffect(() => {
      // Code to run only on component mount
      fetchImage(props.image)
      
    }, [props.image]); // Empty dependency array ensures this effect runs only once

    useEffect(() => {
        const updateVisibleTags = () => {
            const container = tagContainerRef.current;
            if (!container) return;

            let maxTagsToShow = props.tags.length;
            let maxHeight = 68; // Approx height of two lines of chips, adjust based on your CSS
            let currentHeight = container.offsetHeight;


            console.log("CurrentHeight",currentHeight)
            if (currentHeight > maxHeight && maxTagsToShow > 0) {
            maxTagsToShow--;
            setVisibleTagsCount(maxTagsToShow);
            }

            
        };

    updateVisibleTags();
    window.addEventListener('resize', updateVisibleTags);
    console.log(visibleTagsCount)
    return () => {
        window.removeEventListener('resize', updateVisibleTags);
    };
    }, [visibleTagsCount]);

    const handlePopoverOpen = (event) => {
        console.log("OPEN")
        setAnchorEl(event.currentTarget);
      };
    
      const handlePopoverClose = () => {
        console.log("Close")
        setAnchorEl(null);
      };

      const open = Boolean(anchorEl);

return (<>
        <Card sx={{ width: 280, 
        maxWidth:280, 
        height: 350, 
        maxHeight:350, 
        backgroundColor: props.isSelected ? '#f0f0f0' : 'transparent', // Example background color change when selected
        cursor: 'pointer', // Change cursor to pointer on hover
      }}>
      <CardMedia
        component="img"
        height="140"
        image={picture}
        alt="test"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        {props.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Calories: {props.calories}
        </Typography>
        <div ref={tagContainerRef} style={{ display: 'flex', flexWrap: 'wrap', gap: 4, marginTop: 8 }}>
          {props.tags.slice(0, visibleTagsCount).map((tag, index) => (
            <Chip key={index} label={tag} variant="outlined" />
          ))}
          {props.tags.length > visibleTagsCount && (
            <Chip label={`+${props.tags.length - visibleTagsCount}`} 
            variant="outlined"
            onMouseEnter={handlePopoverOpen}
             />
          )}
        </div>
       
      </CardContent>
    </Card>
    <Popover
          id="tag-popover"
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ p: 2 }}>
             {props.tags.slice(visibleTagsCount, ).join(', ')}
          </Typography>
          </Popover>
         </>
)
}