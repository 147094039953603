import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import './IngredientDetails.css';

function IngredientDetails( props ) {


    const [ingredient, setIngredient] = useState("HUU")
    const [calories, setCalories] = useState("")
    const [carb, setCarb] = useState("")
    const [protein, setProtein] = useState("")
    const [fat, setFat] = useState("")
    const [unit, setUnit] = useState("")
    const [tags, setTags] = useState([])
    

    //editRow
    useEffect(() => {
        
        if (props.selectedRow){

            setIngredient(props.selectedRow.ingredient)
            setCalories(props.selectedRow.calories)
            setCarb(props.selectedRow.carb)
            setProtein(props.selectedRow.protein)
            setFat(props.selectedRow.fat)
            setUnit(props.selectedRow.unit)
            setTags(props.selectedRow.tags)
            
        }
    
    // eslint-disable-next-line
    }, [props.selectedRow]);

    return (
        <div class="ingredient-info-list-details">
            <div class="grid-ingredient-item ingredient-item-name">{ingredient}</div>
            <div class="grid-ingredient-item ingredient-item-info">
            <div class="ingredient-info-list">
			<div class="ingredient-description">Calories</div>
            <div class="ingredient-value">{calories} (Kcal)</div>
		</div>
        <div class="ingredient-info-list">
			<div class="ingredient-description">Carb</div>
            <div class="ingredient-value">{carb} (g)</div>
		</div>
        <div class="ingredient-info-list">
			<div class="ingredient-description">Protein</div>
            <div class="ingredient-value">{protein} (g)</div>
		</div>
        <div class="ingredient-info-list">
			<div class="ingredient-description">Fat</div>
            <div class="ingredient-value">{fat} (g)</div>
		</div>
        <div class="ingredient-info-list">
			<div class="ingredient-description">Unit</div>
            <div class="ingredient-value">{unit}</div>
		</div>

            </div>

            <div class="grid-ingredient-item ingredient-item-tag">
                Tags:
            <div style={{ marginTop: '10px' }}>
            {tags.map((tag) => (
                <Chip label={tag} key={tag} style={{ marginRight: '5px' }} />
            ))}
        </div>
            </div>
        </div>
        
    );
}

export default IngredientDetails;