import React, { createContext, useState, useContext, useEffect  } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';


const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {


    const [isLoading, setIsLoading] = useState(true);  // Declaration of user and setUser
    const [user, setUser] = useState(false);  // Declaration of user and setUser

    const login = (token) => {
        localStorage.setItem('authToken', token);  // Store the token in localStorage
        setUserFromToken(token);  // Set user from the token
    };

    const logout = () => {
        localStorage.removeItem('authToken');  // Remove the token from localStorage
        setUser(null);  // Clear the user state
    };

    const setUserFromToken = (token) => {
        try {
            const decoded = jwtDecode(token);  // Decode the token
            console.log(decoded)
            setUser({
                user_id: decoded.user_id,  // Assuming your token has a user_id field
                exp: decoded.exp,
                name: decoded.name,
                role: decoded.role,
            });
        } catch (error) {
            console.error('Failed to decode token:', error);
            setUser(null);
        }
    };


    const isTokenExpired = () => {
        const token = localStorage.getItem('authToken');
        if (!token) return true;

        try {
            const decoded = jwtDecode(token);
            const currentTime = Date.now() / 1000; // JavaScript timestamps are in milliseconds
            
            setUser({
                user_id: decoded.user_id,  // Assuming your token has a user_id field
                exp: decoded.exp,
                name: decoded.name,
                role: decoded.role,
            });

            return decoded.exp < currentTime;
        } catch (error) {
            console.error('Failed to decode token for expiration check:', error);
            return true;
        }
    };

    const makeAuthenticatedRequest = async (method, url, data) => {
        const token = localStorage.getItem('authToken');
        return axios({
            method: method,
            url: url,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    useEffect(() => {
        console.log("CHEK Token")
        if (isTokenExpired()) {
            logout();
        }
        setIsLoading(false)
    }, []);


    return (
        <AuthContext.Provider value={{ user, login, logout, makeAuthenticatedRequest, isLoading }}>
            {children}
        </AuthContext.Provider>
    );
};