
import React, { useState, useEffect, useRef } from 'react';
import { Table, Grid,  Radio, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Autocomplete, CircularProgress, Menu, MenuItem } from '@mui/material';
import useFetchTagsPrepMeal from '../API/useFetchTagsPrepmeal'; // Adjust the import path
import useFetchListIngredients from '../API/fetchedIngredients';
import useLockBodyScroll from '../Context/useLockBodyScroll'; // Import the custom hook

import { useAlert } from '../Context/alertContext'; // Adjust the import path
import CardMeal from './CardMeal'; // Adjust the import path as necessary


const EnhancedTable = (props) => {
const { fetchedTags } = useFetchTagsPrepMeal();
const {fetchedIngredients} = useFetchListIngredients()
const [selectedIngredients, setSelectedIngredients] = useState([]);

  const [search, setSearch] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const loader = useRef(null);

  const [selectedRightClik, setSelectedRightClik] = useState(null);
  const [selected, setSelected] = useState(null);

  const { showAlert } = useAlert(); // Use the alert context

  const [open, setOpen] = useState(false);
  useLockBodyScroll(open); // Use the hook with the current open state

      const refreshData = async () => {
        setLoading(true);
        let allData = []; // Array to store data from all pages

        // Loop through all pages up to the current page
        for (let currentPage = 1; currentPage <= page; currentPage++) {
            let url = `/api/prepmeals?page=${currentPage}`;
                // Initialize the base URL
            if (search.trim() !== '') {
                url += `&search=${encodeURIComponent(search.trim())}`;
            }
        
            if (selectedTags.length > 0) {
                const tagsQuery = selectedTags.join(',');
                url += `&tags=${encodeURIComponent(tagsQuery)}`;
            }
            

            if (selectedIngredients.length > 0) {
              const IngredientsQuery = selectedIngredients.map(item => item.ingredient_id).join(',');
              url += `&ingredientsID=${encodeURIComponent(IngredientsQuery)}`;
          }
            
            try {
                const response = await fetch(url);
                const data = await response.json();
                allData = [...allData, ...data.prepmeals];
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        }

        // Update props.rows only once with all the fetched data
        props.setRows(allData);
        setLoading(false);
    };

    const fetchData = async () => {
        if (!hasMore) return;
        setLoading(true);
    
        // Initialize the base URL
        let url = `/api/prepmeals?page=${page}`;
    
        // Append the search query if it's not empty
        if (search.trim() !== '') {
        url += `&search=${encodeURIComponent(search.trim())}`;
        }
    
        // Append the tags query if any tags are selected
        if (selectedTags.length > 0) {
        const tagsQuery = selectedTags.join(',');
        url += `&tags=${encodeURIComponent(tagsQuery)}`;
        }

        if (selectedIngredients.length > 0) {
          const IngredientsQuery = selectedIngredients.map(item => item.ingredient_id).join(',');
          url += `&ingredientsID=${encodeURIComponent(IngredientsQuery)}`;
      }
        
        try {
        const response = await fetch(url);
        const data = await response.json();
        props.setRows(prev => [...prev, ...data.prepmeals]); // Append new data
        setHasMore(data.hasMore); // Check if more data is available
        setPage(prev => prev + 1); // Increment the page number
        } catch (error) {
        console.error('Failed to fetch data:', error);
        } finally {
        setLoading(false);
        }
    };


    // Fetch data on search or tags change
    useEffect(() => {
      console.log("rows changed!")
      // eslint-disable-next-line
    }, [props.rows]);

        // Fetch data on search or tags change
        useEffect(() => {
          console.log("selectedRow changed!")
          // eslint-disable-next-line
        }, [props.selectedRow]);


    useEffect(() => {
      if (props.isTableRefresh){
        console.log("Update Table")
        refreshData()
        props.setIsTableRefresh(false)
      }

   
      // eslint-disable-next-line
    }, [props.isTableRefresh]);
    

    // Fetch data on search or tags change
    useEffect(() => {
        props.setRows([]); // Clear current data
        setPage(1); // Reset pagination
        setHasMore(true); // Assume more data is available
        fetchData();

        

        // eslint-disable-next-line
      }, [search, selectedTags, selectedIngredients]);


    useEffect(() => {
    const observer = new IntersectionObserver(entries => {
        const firstEntry = entries[0];
        console.log('Observer triggered:', firstEntry.isIntersecting);
        if (firstEntry.isIntersecting && hasMore && !loading) {
        fetchData();

        }
    }, { threshold: 0.1 }); // Lower threshold to make it more sensitive




    const currentElement = loader.current;
    if (currentElement) {
        observer.observe(currentElement);
    }

    return () => {
        if (currentElement) {
        observer.unobserve(currentElement);
        }
    };
    }, [loader, hasMore, loading]);

    const handleRightClick = (event, row, index) => {

        setSelectedRightClik(row)
        event.preventDefault(); // Prevent the bprops.rowser's context menu from opening
        //props.setSelectedRow(row); // Store the clicked row's data
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
              }
            : // Reopen the menu at the same position
              null,
        );
      };

      const handleRowClick = (index) => {
        console.log("clicked")

        if (selected === index){
          props.setSelectedRow(null)
          setSelected(null);
        }else{
          props.setSelectedRow(props.rows[index])
          setSelected(index);
        }

    };

    const handleClose = () => {
        setContextMenu(null);
      };
    
    const handleDelete = async () => {

    console.log("DELETE PREPMEAL")
    console.log(selectedRightClik)
    if (!selectedRightClik) return;
    
    const url = `/api/prepmeal/${selectedRightClik._id}`;
    try {
        const response = await fetch(url, {
        method: 'DELETE'
        });
        const result = await response.json();
        if (response.ok) {
        // Successfully deleted the ingredient, now remove it from the table
        setSelectedRightClik(null)
        props.setRows(prevRows => prevRows.filter(row => row._id !== selectedRightClik._id));
        console.log(`Deleted ihuhuhuhuhuhuhuhuhuhunt with ID: ${selectedRightClik._id}`);
        showAlert('success', result.message);
        } else {
        // Handle errors if the deletion was unsuccessful
        console.log(result)
        console.error('Failed to delete the ingredient:', response.status);
        showAlert('error', response.error);
        }
    } catch (error) {
        console.error('Error deleting ingredient:', error);
    }
    
    // Close the context menu
    handleClose();
    };
    
    const handleEdit = () => {
    console.log('Edit action on row:', props.selectedRow);
    console.log('RIGHT CLICKED', selectedRightClik)
    props.setEditRow(selectedRightClik)
    // Implement edit logic here
    handleClose();
    };


  return (
    <Paper sx={{ overflow: 'hidden' }}>
      <Grid container spacing={2}>  {/* Adjust 'spacing' to control the space between fields */}
        <Grid item xs={4}> {/* 'xs' controls the width on extra-small to small screens */}
          <TextField
            label="Search Prepmeal"
            variant="outlined"
            value={search}
            fullWidth
            onChange={(e) => {
              setSearch(e.target.value);
              props.setRows([]); // Reset data
              setPage(1); // Reset page
              setHasMore(true); // Reset pagination
            }}
            sx={{ m: 2 }}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            multiple
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={fetchedTags}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField {...params} label="Filter by Tags" />}
            value={selectedTags}
            onChange={(event, newValue) => {
              setSelectedTags(newValue);
              props.setRows([]); // Reset data
              setPage(1); // Reset page
              setHasMore(true); // Reset pagination
            }}
            sx={{ m: 2 }}
        />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            multiple
            options={fetchedIngredients}
            getOptionLabel={(option) => option.ingredient}
            renderInput={(params) => <TextField {...params} label="Filter by Ingredients" />}
            value={selectedIngredients}
            onChange={(event, newValue) => {
              setSelectedIngredients(newValue);
              props.setRows([]); // Reset data
              setPage(1); // Reset page
              setHasMore(true); // Reset pagination
            }}
            sx={{ m: 2 }}
        />
        </Grid>
      </Grid>
      <Grid container spacing={2} height="100%">
        {props.rows.map((food, index) => (
            <Grid onClick={() => handleRowClick(index)} onContextMenu={(e) => handleRightClick(e, food, index)} item key={food.id} xs={12} sm={6} md={2.4} style={{ display: 'flex', justifyContent: 'center' }}>
            <CardMeal isSelected={selected===index} title={food.prepmeal} image={food.picture_id} calories={food.calories} tags={food.tags} />
            </Grid>
        ))}
        {loading && (
                  <CircularProgress />
            )}
        <div ref={loader} style={{ height: '50px', margin: '10px 0' }}>
        </div>
        </Grid>

        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
            contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
        >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
    </Paper>
  );
};

export default EnhancedTable;
