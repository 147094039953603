import React, { useState, useEffect } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Autocomplete } from '@mui/material';
import useFetchTags from '../API/useFetchTags'; // Adjust the import path
import { useAlert } from '../Context/alertContext'; // Adjust the import path


const IngredientForm = (props) => {

  const { fetchedTags, loading, error } = useFetchTags();

  const [ingredient, setIngredient] = useState('');
  const [calories, setCalories] = useState('');
  const [carb, setCarb] = useState('');
  const [protein, setProtein] = useState('');
  const [fat, setFat] = useState('');
  const [unit, setUnit] = useState('');
  const [tags, setTags] = useState([]);
  const { showAlert } = useAlert(); // Use the alert context


  //editRow
  useEffect(() => {
    if (props.editRow){
      console.log("EDIT THIS", props.editRow )
      setValues(props.editRow)
    }
    // eslint-disable-next-line
  }, [props.editRow]);


  const handleClose = () => {
    resetValue()
    props.setOpen(false);
    props.handleUpdate(null)
  };

  const resetValue = () => {
    setIngredient( "" )
    setCalories( "" )
    setCarb( "" )
    setProtein( "" )
    setFat( "" )
    setUnit( "" )
    setTags( [])
  }

  const setValues = (selectedRow) => {

    setIngredient( selectedRow["ingredient"] )
    setCalories( selectedRow["calories"] )
    setCarb(selectedRow["carb"])
    setProtein(selectedRow["protein"])
    setFat(selectedRow["fat"])
    setUnit(selectedRow["unit"])
    setTags(selectedRow["tags"])
    props.setOpen(true);
  }


  
  const handleSubmit = async () => {


    console.log("submit", props.editRow)
    const ingredientData = {
        ingredient,
        calories: Number(calories), // ensure numerical data is properly formatted
        carb: Number(carb),
        protein: Number(protein),
        fat: Number(fat),
        unit,
        tags
      };

    let methodRequest = ""
    let urlRequest = ""


    if (props.editRow){
      methodRequest = "PUT"
      urlRequest = '/api/ingredient/'+props.selectedRow["_id"]
      
    }else{
      methodRequest = "POST"
      urlRequest = '/api/ingredient'
    }

    try {
      const response = await fetch(urlRequest, {
        method: methodRequest,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(ingredientData)
      });

      const result = await response.json();
      if (!response.ok) {
          if (response.status === 400 || response.status === 409) {
              // Bad Request
              const errorMessage = result.error || 'There is an error with the input.';
              throw new Error(errorMessage);
          } else if (response.status === 500) {
              // Internal Server Error
              throw new Error('Server error occurred. Please try again later.');
          } else {
              // Other types of errors
              throw new Error('An unexpected error occurred. Please try again.');
          }
      }
  

      //setAlertInfo({ open: true, severity: 'success', message: 'Ingredient added successfully!' });
      if (!props.editRow) props.updateTable()
      showAlert('success', result.message);
      props.handleUpdate(ingredientData)
      handleClose(); // Optionally close the dialog on success
      
    } catch (error) {
      console.error('Error submitting ingredient:', error);
      showAlert('error', error.message);
      
    }
      
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Add New Ingredient</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="ingredient"
            label="Ingredient"
            type="text"
            fullWidth
            variant="standard"
            value={ingredient}
            onChange={(e) => setIngredient(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            id="calories"
            label="Calories"
            type="number"
            fullWidth
            variant="standard"
            value={calories}
            onChange={(e) => setCalories(e.target.value.replace(/,/g, '.'))}
            required
          />
          <TextField
            margin="dense"
            id="carb"
            label="Carb"
            type="number"
            fullWidth
            variant="standard"
            value={carb}
            onChange={(e) => setCarb(e.target.value.replace(/,/g, '.'))}
            required
          />
          <TextField
            margin="dense"
            id="protein"
            label="Protein"
            type="number"
            fullWidth
            variant="standard"
            value={protein}
            onChange={(e) => setProtein(e.target.value.replace(/,/g, '.'))}
            required
          />
          <TextField
            margin="dense"
            id="fat"
            label="Fat"
            type="number"
            fullWidth
            variant="standard"
            value={fat}
            onChange={(e) => setFat(e.target.value.replace(/,/g, '.'))}
            required
          />
          <TextField
            select
            label="Unit"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
            fullWidth
            margin="dense"
            variant="standard"
            required
          >
            <MenuItem value="grams">gram (g)</MenuItem>
            <MenuItem value="milliliters">milliliters (ml)</MenuItem>
            <MenuItem value="piece">pc(s).</MenuItem>
          </TextField>
          <Autocomplete
                multiple
                options={fetchedTags} // Use fetchedTags here
                freeSolo
                value={tags} // Use this state for handling selected tags
                onChange={(event, newValue) => {
                setTags(newValue);
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="Tags"
                    placeholder="Add Tags"
                />
                )}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{props.editRow ? "Update" : "Create"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IngredientForm;
