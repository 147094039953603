import React, {useEffect, useState} from 'react';
import { Tooltip, Box, Menu, MenuItem, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, CardContent, Typography, CardMedia, CircularProgress } from '@mui/material';
import { useAlert } from '../Context/alertContext'; // Adjust the import path


const HoverIngredient = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [quantity, setQuantity] = useState(props.portion.quantity);
    const [isActive, setIsActive] = useState(false);
    const [image, setImage] = useState(false);
    const [ingredient, setIngredient] = useState({
        'calories':"0",
        'carb':"0",
        'protein':"0",
        'fat':"0",
        'picture_id':"asdadfa"
    })
    const { showAlert } = useAlert(); // Use the alert context


  const handleContextMenu = (event) => {
      event.preventDefault(); // Prevent the default context menu
      setAnchorEl(event.currentTarget); // Open the custom menu
  };

  const handleCloseMenu = () => {
      setAnchorEl(null);
  };


  const handleEdit = () => {

      setQuantity(props.portion.quantity)
      handleCloseMenu();
      setDialogOpen(true);
  };

  const handleDelete = async () => {
    handleCloseMenu();
    console.log("Delete", ingredient,"meal_id", props.selectedRow._id )
    if (!props.selectedRow) return;
    let url = `/api/prepmeal/${props.selectedRow._id}/portion`;
    url += "?ingredient_id="+ingredient._id;

    console.log(url)
    try {
        const response = await fetch(url, {
        method: 'DELETE'
        });
        const result = await response.json();
        if (response.ok) {
        // Successfully deleted the ingredient, now remove it from the table

        console.log(`Deleted Portion with ID: ${ingredient._id}`);

        props.handleUpdate(props.selectedRow)
        showAlert('success', result.message);
        } else {
        // Handle errors if the deletion was unsuccessful
        console.error('Failed to delete the ingredient:', response.status);
        showAlert('error', result.error);
        }
    } catch (error) {
        console.error('Error deleting ingredient:', error);
    }
};

  const handleCloseDialog = () => {
      setDialogOpen(false);
  };




  const handleSave = async () => {
      console.log("New quantity:", quantity); // Here, integrate with your state management or backend
      if (!props.selectedRow) return;
      let url = `/api/prepmeal/${props.selectedRow._id}/portion`;


      const newPortion = {
          "ingredient_id": ingredient._id,
          "quantity": quantity
      }
  
      console.log(url)
      try {
          const response = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newPortion)
          });
          const result = await response.json();
          if (response.ok) {
          // Successfully deleted the ingredient, now remove it from the table
  
          console.log(`Change Portion with ID: ${ingredient._id}`);
  
          props.handleUpdate(props.selectedRow)
          showAlert('success', result.message);
          } else {
          // Handle errors if the deletion was unsuccessful
          console.error('Failed to delete the ingredient:', response.status);
          showAlert('error', result.error);
          }
      } catch (error) {
          console.error('Error deleting ingredient:', error);
      }
      setDialogOpen(false);
  };

        // Function to handle tooltip opening
        const handleTooltipOpen = () => {
            setIsActive(true);
            fetchImage();
        };


        const fetchImage = async () => {
            try {
                const response = await fetch(`/api/ingredient/${ingredient.picture_id}/picture`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const imageBlob = await response.blob();
                const imageObjectURL = URL.createObjectURL(imageBlob);
                setImage(imageObjectURL);
            } catch (error) {
                console.log('Failed to fetch image');
            }
        };


    useEffect(() => {
        console.log(props.portion)
        if (props.portion){
            // Fetch data from API when the component mounts or props.portion.ingredient_id changes
            fetch(`/api/ingredient/${props.portion.ingredient_id}`)
            .then(response => response.json())
            .then(data => {
                setIngredient(data); // Assuming the API returns the ingredient data
            })
            .catch(error => console.error('Error fetching ingredient data:', error));
        }
      }, [props.portion]); // Run effect whenever props.portion.ingredient_id changes
    
   
  const renderCardContent = () => (
    <CardContent style={{width:"27vh"}}>
      <Typography variant="h6" component="div">
        {props.portion.ingredient}
      </Typography>
      {image ? (
      <CardMedia
          component="img"
          height="110"
          image={image}
          alt="Sample Image"
        />):
        (  <div style={{ position: 'relative', width: '100%', height: '110px' }}>
              <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
            </div>
          )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body1" sx={{ textAlign: 'left', flex: 1, fontWeight: "bold" }}>

        </Typography>
        <Typography variant="body1" sx={{  textAlign: 'center',flex: 1, fontWeight: "bold" }}>
          Ingredient ({ props.portion.unit === "piece" ? "1pc":"100g"})
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'right',flex: 1, fontWeight: "bold" }}>
          Portion ({props.portion.quantity}{ props.portion.unit === "piece" ? "pc":"g"})
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body1" sx={{ textAlign: 'left', flex: 1 }}>
          Calories:
        </Typography>
        <Typography variant="body1" sx={{  textAlign: 'center',flex: 1 }}>
          {ingredient.calories}Kcal
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'right',flex: 1 }}>
          {props.portion.calories}Kcal
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body1" sx={{ textAlign: 'left', flex: 1 }}>
          Carbs:
        </Typography>
        <Typography variant="body1" sx={{  textAlign: 'center',flex: 1 }}>
          {ingredient.carb}g
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'right',flex: 1 }}>
          {props.portion.carb}g
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body1" sx={{ textAlign: 'left', flex: 1 }}>
          Protein:
        </Typography>
        <Typography variant="body1" sx={{  textAlign: 'center',flex: 1 }}>
          {ingredient.protein}g
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'right',flex: 1 }}>
          {props.portion.protein}g
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body1" sx={{ textAlign: 'left', flex: 1 }}>
          Fat:
        </Typography>
        <Typography variant="body1" sx={{  textAlign: 'center',flex: 1 }}>
          {ingredient.fat}g
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'right',flex: 1 }}>
          {props.portion.fat}g
        </Typography>
      </Box>
    </CardContent>
  );

  return (

    <>
    <Tooltip  onOpen={handleTooltipOpen} title={renderCardContent()} arrow>
      	  <div className="prepmeal-info-list" onContextMenu={handleContextMenu}>
            <div className="prepmeal-description">{props.portion.ingredient}</div>
            <div className="prepmeal-value">{props.portion.quantity}{ props.portion.unit === "piece" ? "pc":"g"}</div>
        </div>
    </Tooltip>
    <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleCloseMenu}
>
      <MenuItem onClick={handleEdit}>Edit</MenuItem>
      <MenuItem onClick={handleDelete}>Delete</MenuItem>
    </Menu>
    <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Edit Quantity</DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="quantity"
                label="Quantity"
                type="text"
                fullWidth
                variant="standard"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
        </DialogActions>
    </Dialog>
</>
  );
};

export default HoverIngredient;