import { useEffect, useState } from 'react';

// Function to fetch ingredients from the API
const useFetchListIngredients = () => {
  const [fetchedIngredients, setFetchedIngredients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchIngredients = async () => {
      setLoading(true);
      try {
        const response = await fetch('/api/ingredients/all');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setFetchedIngredients(data); // assuming the API returns an array of ingredients
      } catch (err) {
        setError(err.message);
        console.error("Failed to fetch ingredients:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchIngredients();
  }, []);

  return { fetchedIngredients, loading, error };
};



export default useFetchListIngredients;
