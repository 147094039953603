import React, { createContext, useState, useContext } from 'react';
import { Snackbar, Alert } from '@mui/material';

// Create the context
const AlertContext = createContext();

// Provider component
export const AlertProvider = ({ children }) => {
    const [alertInfo, setAlertInfo] = useState({
        open: false,
        severity: 'info', // default severity
        message: ''
    });

    const showAlert = (severity, message) => {
        setAlertInfo({
            open: true,
            severity,
            message
        });
    };

    const closeAlert = () => {
        setAlertInfo({ ...alertInfo, open: false });
    };

    return (
        <AlertContext.Provider value={{ showAlert, closeAlert }}>
            {children}
            <Snackbar 
            open={alertInfo.open} 
            autoHideDuration={6000} 
            onClose={closeAlert}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}  // Set the position here
            >
                <Alert onClose={closeAlert} 
                    severity={alertInfo.severity} 
                    sx={{ width: '100%' }}>
                    {alertInfo.message}
                </Alert>
            </Snackbar>
        </AlertContext.Provider>
    );
};

// Hook to use alert
export const useAlert = () => useContext(AlertContext);
