import { useEffect, useState } from 'react';

// Function to fetch tags from the API
const useFetchTagsPrepMeal = () => {
  const [fetchedTags, setFetchedTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTags = async () => {
      setLoading(true);
      try {
        const response = await fetch('/api/prepmeals/tags');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setFetchedTags(data); // assuming the API returns an array of tags
      } catch (err) {
        setError(err.message);
        console.error("Failed to fetch tags:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchTags();
  }, []);

  return { fetchedTags, loading, error };
};



export default useFetchTagsPrepMeal;
