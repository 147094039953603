import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import './PrepmealDetails.css';
import HoverIngredient from './HoverIngredient'

function PrepmealDetails( props ) {


    const [prepmeal, setPrepmeal] = useState("")
    const [calories, setCalories] = useState("")
    const [carb, setCarb] = useState("")
    const [protein, setProtein] = useState("")
    const [fat, setFat] = useState("")
    const [portion, setPortion] = useState("")
    const [tags, setTags] = useState([])
    const [portionDefinition, setPortionDefinition] = useState([])
    

    //editRow
    useEffect(() => {
        
        if (props.selectedRow){

            setPrepmeal(props.selectedRow.prepmeal)
            setCalories(props.selectedRow.calories)
            setCarb(props.selectedRow.carb)
            setProtein(props.selectedRow.protein)
            setFat(props.selectedRow.fat)
            setPortion(props.selectedRow.total_portion)
            setTags(props.selectedRow.tags)
            setPortionDefinition(props.selectedRow.portions_definition)

            console.log("portions",props.selectedRow.portions_definition)
        }
    
    // eslint-disable-next-line
    }, [props.selectedRow]);

    return (
        <div class="prepmeal-info-list-details">
            <div class="grid-prepmeal-item prepmeal-item-name">{prepmeal}</div>
            <div class="grid-prepmeal-item prepmeal-item-info">
                <div class="prepmeal-info-list">
                    <div class="prepmeal-description">Calories</div>
                    <div class="prepmeal-value">{calories} (Kcal)</div>
                </div>
                <div class="prepmeal-info-list">
                    <div class="prepmeal-description">Portion</div>
                    <div class="prepmeal-value">{portion}</div>
                </div>
                <div class="prepmeal-info-list">
                    <div class="prepmeal-description">Carb</div>
                    <div class="prepmeal-value">{carb} (g)</div>
                </div>
                <div class="prepmeal-info-list">
                    <div class="prepmeal-description">Protein</div>
                    <div class="prepmeal-value">{protein} (g)</div>
                </div>
                <div class="prepmeal-info-list">
                    <div class="prepmeal-description">Fat</div>
                    <div class="prepmeal-value">{fat} (g)</div>
                </div>


            </div>

            <div class="grid-prepmeal-item prepmeal-item-titlelist">
                Tags:
            <div style={{ marginTop: '10px' }}>
            {tags.map((tag) => (
                <Chip label={tag} key={tag} style={{ marginRight: '5px' }} />
            ))}
        </div>
            </div>
            <div class="grid-prepmeal-item prepmeal-item-titlelist" >Ingredients:
                <div class="prepmeal-item-list-ingredient">
                    {portionDefinition.map((portion,index) => (
                            <HoverIngredient key={index} selectedRow={props.selectedRow} portion={portion} handleUpdate={props.handleUpdate}/>
                    ))}
                </div>
            </div>
        </div>
        
    );
}

export default PrepmealDetails;