import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';

import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';  // If you have a Google icon or use an appropriate icon
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';

function HomePage() {

    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();

    const handleLogin = () => {
        // Redirect to your Flask Google login URL
        
        var url = window.location.href

        if(url.includes("localhost") ){
            url = "http://localhost:5111/api/login"
        }else{
            url = "https://www.nutriplanchef.de/api/login"
        }
        

        window.location.href = url;
      };

    const handleLogout = () => {
        auth.logout()
    };

    useEffect(() => {

        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        if (token) {
            console.log("Hello")
            localStorage.setItem('authToken', token);  // Storing token in localStorage for simplicity
            auth.login(token);  // Decode or validate token to set user

            console.log(auth.user)
        }
    }, [location]);

    if (auth && auth.user) {
        console.log(auth.user)
        return <div>
                    <h1>Welcome {auth.user.name}</h1>
                    {auth.user.role === "admin" ? <h3>You have admin rights. </h3>:""}
                    <Button
                    variant="contained"
                    startIcon={<LogoutIcon />}
                    onClick={handleLogout}
                    style={{ backgroundColor: '#4285F4', color: 'white' }}
                    >
                        Logout
                    </Button>
                </div>
      } else {
        return <div>
                    <h1> Hello, Please Log in! </h1>
                    <Button
                    variant="contained"
                    startIcon={<GoogleIcon />}
                    onClick={handleLogin}
                    style={{ backgroundColor: '#4285F4', color: 'white' }}
                    >
                        Login with Google
                    </Button>
                </div>
      }


}

export default HomePage;