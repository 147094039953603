import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Box, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function ChangePictureDialog({ open, handleClose, updatePicture }) {
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null); 

    // Handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.substr(0, 5) === "image") {
            setLoading(true);  // Start loading
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
                setLoading(false);  // Stop loading after the image is loaded
            };
            reader.onerror = () => {
                setLoading(false);  // Stop loading on error
            };
            reader.readAsDataURL(file);
        } else {
            setFile(null);
            setPreview(null);
        }
    };
    // Handle image update
    const handleUpdate = () => {
        if (file) {
            updatePicture(file); // Implement this function to actually update the picture
            handleClose();
        }
    };

    // Reset form inputs and preview
    const resetForm = () => {
        setFile(null);
        setPreview(null);
        setLoading(false);  // Reset loading state
        if (fileInputRef.current) {
            fileInputRef.current.value = "";  // Reset the file input element
        }
    };

    // Effect to reset state when dialog is closed
    useEffect(() => {
        if (!open) {
            resetForm();
        }
    }, [open]);
    return (
        <Dialog open={open} onClose={() => { resetForm(); handleClose(); }} aria-labelledby="change-picture-dialog">
            <DialogTitle id="change-picture-dialog-title">
                Change Picture
                <IconButton
                    aria-label="close"
                    onClick={() => { resetForm(); handleClose(); }}
                    style={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
            <Box>
        <Typography variant="body2" gutterBottom>
            Upload or select a new image for the ingredient.
        </Typography>
        <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            accept="image/*"
        />
        {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
                <CircularProgress />
            </Box>
        ) : preview && (
            <Box mt={2} textAlign="center">
                <img src={preview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '400px' }} />
            </Box>
        )}
    </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { resetForm(); handleClose(); }}>Cancel</Button>
                <Button onClick={handleUpdate} color="primary" disabled={!file}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ChangePictureDialog;