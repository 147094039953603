import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import ScheduleConfiguration from './ScheduleConfiguration';
import NutritionalConfiguration from './NutritionalConfiguration';
import MealSelection from './MealSelection';

export default function CreateNewGoalDialog() {
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const steps = getSteps();

  const [scheduleData, setScheduleData] = useState({
    days: "3",
    startDate: '2024-05-30',
    breakfast: 1,
    meals: 2,
    snacks: 1,
  });

  const [nutritionValues, setNutritionValues] = useState({
    calories: 2500,
    carb: 50,
    protein: 35,
    fat: 15,

  });

  const handleScheduleDataChange = (newData) => {
    setScheduleData((prevData) => ({
      ...prevData,
      ...newData
    }));
  };



  function getSteps() {
    return ['Schedule Configuration', 'Nutritional Goals', 'Meal Selection'];
  }
  
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <ScheduleConfiguration initialData={scheduleData}  onChildDataChange={handleScheduleDataChange} activeStep={activeStep} setActiveStep={setActiveStep} isValid={isValid} setIsValid={setIsValid}/>;
      case 1:
        return <NutritionalConfiguration initialData={nutritionValues}  onChildDataChange={setNutritionValues} activeStep={activeStep} setActiveStep={setActiveStep} isValid={isValid} setIsValid={setIsValid}/>;
      case 2:
        return <MealSelection />;
      default:
        return 'Unknown step';
    }
  }

  const handleNext = () => {
    setIsValid(true)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleOpen}>
        Open Dialog
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {getStepContent(activeStep)}
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button onClick={activeStep === steps.length - 1 ? handleClose : handleNext}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}