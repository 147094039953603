import React, { useState, useEffect } from 'react';
import { CardMedia, IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ChangePictureDialog from './Dialog/ChangePictureDialog';
import { useAlert } from '../Context/alertContext'; // Adjust the import path
import defaultImage from "../default_prepmeal.png";

export default function PicturePrepmeal(props) {




    const [picture, setPicture] = useState(defaultImage);
    const [hover, setHover] = useState(false); // State to manage hover effect
    const [dialogOpen, setDialogOpen] = useState(false);

    const { showAlert } = useAlert(); // Use the alert context

    // Function to convert a file to a Base64 string
    const fileToBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    // The updatePicture function
    const updatePicture = async (file, prepmealId) => {
        try {

            prepmealId = props.selectedRow._id
            // Convert file to Base64
            const base64Image = await fileToBase64(file);

    
            // Construct the fetch options with headers and body
            const fetchOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({ picture_data: base64Image })
            };
    
            // Sending the POST request to the server with the Base64 encoded image data
            const response = await fetch(`/api/prepmeal/${prepmealId}/picture`, fetchOptions);
    
            if (!response.ok) {
                throw new Error('Failed to upload the image.');
            }
    
            const responseData = await response.json();
            console.log('Image updated successfully:', responseData);
            props.handleUpdate(props.selectedRow)
            showAlert('success', 'Image updated successfully!');
    
        } catch (error) {
            console.error('Error updating the image:', error);
            showAlert('error', error);
        }
    };



    const fetchImage = async (pictureId) => {
            try {
                const response = await fetch(`/api/prepmeal/${pictureId}/picture`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const imageBlob = await response.blob();
                const imageObjectURL = URL.createObjectURL(imageBlob);
                setPicture(imageObjectURL);
            } catch (error) {
                console.log('Failed to fetch image');
            }
        };

    useEffect(() => {
        if (props.selectedRow && props.selectedRow.picture_id) {
            fetchImage(props.selectedRow.picture_id);
        }
        // eslint-disable-next-line
    }, [props.selectedRow]);

    return (
        <Box
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ position: 'relative', width: '100%', height: '100%' }}
        >
            <CardMedia
                component="img"
                height="100%"
                image={picture}
                alt="Ingredient"
            />
            {hover && (
                <IconButton
                    style={{
                        position: 'absolute',
                        top: '10px', // Adjust the position as needed
                        right: '10px', // Adjust the position as needed
                        color: 'rgba(255, 255, 255, 0.8)', // Icon color
                        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Icon background, semi-transparent
                    }}
                    onClick={() => setDialogOpen(true)}
                >
                    <EditIcon />
                </IconButton>
            )}
            <ChangePictureDialog
                open={dialogOpen}
                handleClose={() => setDialogOpen(false)}
                updatePicture={(file) => updatePicture(file)}
            />
        </Box>
    );
}